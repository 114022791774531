import React, {Component} from 'react'
const latestVersion = require('../../static/latestversion.json')

class Puppies extends Component {
  componentDidMount() {
    const androidApkURL = `https://github.com/staltz/manyverse/releases/download/v${latestVersion}/manyverse-${latestVersion}.apk`
    window.location.replace(androidApkURL)
  }

  render() {
    return <div />
  }
}

export default Puppies
